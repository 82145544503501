//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import TuoShiLOGO from "../../components/TuoShiLOGO.vue";
export default {
  components: { TuoShiLOGO },
  data () {
    return {
      checks: 1,
      money: "",
    };
  },
  methods: {},
};
